.Admin {
    display: flex;
    margin-top: 10px;
}

.AdminSidebar {
    height: 100vh;
    width: 250px;
    background-color: white;
    display: flex;
    color: #000000;
}

.DataAnonBody {
    padding-top: 12px;
    padding-left: 20px;
    background-color: white;
    margin-left: 10px;
    width: 100%;
    font-size: 10pt;
    font-weight: 400;
}

.dataAnonDefaultLabel {
    font-size: 1.5em;
    font-weight: 500;
}

.roleMessageLabel {
    font-size: 1.1em;
    margin-top: 1%;
    margin-bottom: 2%;
    margin-left: 1%;
    width: 60%;
}

.rolePermissionTable {
    margin-left: 1%;
    margin-top: -3%;
}

.PhoneAnonymizationBody {
    padding-top: 12px;
    padding-left: 20px;
    background-color: white;
    margin-left: 10px;
    width: 100%;
    font-size: 10pt;
}

.Navlink {
    font-size: 11pt;
}

.Placeholder {
    font-size: 10pt;
    color: black;
    opacity:  0.75;
    padding-left: 5px;
}

.sidebarIcon {
    position: relative;
    bottom: 2px;
    font-size: 20px;
    margin-right: 8px;
}

.openModalBtn {
    overflow: auto;
    width: 200px;
    height: 40px;
    border: none;
    border-radius: 6px;
    background-color: cornflowerblue;
    color: white;
    cursor: pointer;
}

#Overrides {
    width: 85%;
    margin-top: 3%;
    margin-bottom: 1%;
}

.dataAnonTableLabel {
    font-size: 1.5em;
    font-weight: 500;
}

.btn.btn-primary:disabled {
    background-color: #EAECF0 !important;
    color: #98A2B3 !important;
    cursor: not-allowed !important;
    opacity: 1 !important;
    border: none !important;
}

.asterisk {
    color: #C32112;
}
